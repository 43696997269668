.navbar_custom {
  background-color: #B1E0D8;   
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.34);
  -moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.34);
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.34);
}

.navbar_custom:hover {
  background-color: #B1E0D8;   
  -webkit-box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.51);
  -moz-box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.51);
  box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.51);
}

.navbar_title {
  font-size: 24;
}


.brand_custom {
  margin-left: 10px;  
}

.activePage {
  margin-left: 20px;
  color: #C00000;
  text-decoration: none;
  font-weight: 600;
  font-size: large;   
}
  
.inactive {
  margin-left: 20px;
  color: #cfe0f9;
  text-decoration: none;
  font-weight: 600;
  font-size: large;   
}

.card {  
  border-radius: 0;
  border-width: 2px;
  border-color: white;
  width: '100%';
  cursor: pointer;
  background-color: #eaeaea;  
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.51);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.51);
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.51);
}

.card:hover {
  border-color: white;
  -webkit-box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.51);
  -moz-box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.51);
  box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.51);
}

