
.navbar_button:hover {
  cursor: pointer;
  text-align: center;  
  margin-left: 20px;
}

.navbar_button {
  cursor: pointer;
  text-align: center;  
  margin-left: 20px;    
}

.text {
  font-size: 32;
  color: 'red';
}

.page-item .page-link {
  background: red;
}

.custom_pagination {    
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.34);
  -moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.34);
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.34);
}

.custom_pagination:hover {  
  -webkit-box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.51);
  -moz-box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.51);
  box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.51);
}
